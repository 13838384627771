<template lang="pug">
    #Videos
        ul
            li(v-for="item in lastVideos")
                a(:href="`https://www.youtube.com/watch?v=${item.id.videoId}`" target="_blank")
                    .img 
                        img(:src="item.snippet.thumbnails.medium.url").image
                    .container
                        .title
                            h2.titulo {{ item.snippet.title }}
                        .publish
                            .icon    
                                img(src="@images/comunicacao/coamo-logo-branco.png").logo
                            .text
                                p {{ item.snippet.channelTitle}}
                                p.date {{ formatDate(item.snippet.publishedAt) }}
</template>


<script>
import { props } from '@/mixins/component' 
import Axios from 'axios'

export default {
    name: "ultimos-videos",
    props,

    data() {
        return {
            lastVideos: {},
            channelId: '',
            apiYoutube: '',
        }
    },
    methods: {
        async getVideos() {
            const axios = Axios.create()
            const response = await axios.get(`https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.channelId}&order=date&maxResults=4&key=${this.apiYoutube}`)
            this.lastVideos = response.data.items
        },
        formatDate(date, full = false) {
            const objData = new Date(date)
            const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
            
            let formatted;
            if (full) {
                formatted = `${objData.getDate()}${objData.getMonth()}/${objData.getFullYear()} ${objData.getHours()}:${objData.getMinutes()}`
            } else {
                formatted = `${objData.getDate()} de ${month[objData.getMonth()]}`
            }
            return formatted
        },
        getSettings() {
            for (let setting of this.settings) {
                if (setting.attribute === 'apiYoutube') this.apiYoutube = setting.value;
                if (setting.attribute === 'channelIdYoutube') this.channelId = setting.value;
            }
        },
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    computed: {
        settings() {
            return this.$store.state.settings
        },
    },
    watch: {
        settings: function () {
            this.getSettings()
            this.lastVideos = this.getVideos()
        }
    }
}
</script>

<style lang="stylus" scoped src="./UltimosVideos.styl"></style>
